import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Marca } from '../_model/marca';

@Injectable({
  providedIn: 'root'
})
export class MarcaService {

  _URLApiBase: string = `${environment.Url}`;
  constructor(private http: HttpClient) { }

  
  lstMarca(marca) {
    return this.http.get<Marca[]>(`${this._URLApiBase}marca/GetMarcas/${marca}`);
  }
  Mantenimiento(marca:any) {
    return this.http.post(`${this._URLApiBase}marca/UpdMarca`, marca);
  }
  eliminar(marca: any) {
    return this.http.post(`${this._URLApiBase}marca/UpdMarca`,marca);
  }

}
